import React, {FC} from "react";
import Code from "../../components/code";
import Layout from "../../components/layout/layout-sidebar";
import Seo from "../../components/seo";
import {sidebarItemsGettingStarted} from "../../data/sidebar-items-getting-started";

const ReducersPage: FC = () => (
    <Layout sidebarItems={sidebarItemsGettingStarted}>
        <Seo
            title="Getting started with grid reducers"
            description="Instructions for working with helper functions that reduce a grid to a single value."
        />
        <div className="flex-1 flex flex-col">
            <section className="p-8 flex-1">
                <h1>{`Grid reducers`}</h1>
                <h2>{`What are reducers?`}</h2>
                <p>{`Reducers take a grid instance and reduce it to a single value.`}</p>
                <Code code={codeExample1} language="javascript" />
            </section>
        </div>
    </Layout>
);

export default ReducersPage;

const codeExample1 = `import {createGridFromArray2D} from "gridl/core";
import {reduceGrid} from "gridl/reducers";

const grid = createGridFromArray2D([
    [1, 2, 3],
    [4, 5, 6],
]);
const initialValue = 10;
const sum = reduceGrid(grid, (acc, cellValue: number) => {
    return acc + cellValue;
}, initialValue);
// => 31 (10 + 1 + 2 + 3 + 4 + 5 + 6)`;
